<template>
    <div class="relative i_w">
        <div class="i_w__c">
            <div class="icon">
                <i class="fi" :class="item.icon" />
            </div>
            <div class="name">{{ item.title }}</div>
        </div>
        <div class="i_w__r">
            <a-badge 
                v-if="item.badge" 
                :count="getCounter"
                :number-style="{
                    boxShadow: '0 0 0 0',
                    backgroundColor: primaryColor
                }"/>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            primaryColor: state => state.config.primaryColor
        }),
        getCounter() {
            return this.$store.getters['navigation/getMenuCounter'](this.item.name)
        }
    }
}
</script>

<style lang="scss" scoped>
.icon{
    font-size: 21px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    .fi{
        width: 21px;
        height: 21px;
        margin-right: 12px;
    }
}
.name{
    word-wrap: break-word;
    font-weight: 300;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.835rem;
}
.i_w{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--borderRadius);
    padding: 9px 10px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &__c{
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__r{
        padding-left: 8px;
    }
}
</style>